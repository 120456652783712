const styles = ({ typography }) => ({
  container: {
    padding: `${typography.pxToRem(32)} 0`,
    margin: `${typography.pxToRem(16)} 0`,
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(290px, 1fr))`,
    gap: '2rem',
  },
});

export default styles;
