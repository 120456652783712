import { useStaticQuery, graphql } from 'gatsby';

const useBlogPosts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: { order: DESC, fields: publishDate }) {
          edges {
            node {
              title
              author {
                name
              }
              slug
              publishDate
              body {
                childMarkdownRemark {
                  excerpt(format: HTML, pruneLength: 200)
                  timeToRead
                }
              }
              heroImage {
                fluid(maxWidth: 360) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default useBlogPosts;
