const content = {
  tagline: 'Staff Front-End Engineer (@Symphony)',
  title: 'Radovan Skendžić',
  subtitle: `Čarobnjak 36. nivo.
   
  Otac, ujak, stric, kamen, makaze, list. 
  
  Umirovljeni glumac Kulturnog Centra Inđija. 
  #odolevaj`,
};

export default content;
