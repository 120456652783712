import { FaGithub, FaLinkedin, FaYoutube, FaTwitter } from 'react-icons/fa';
import React from 'react';

const content = {
  title: 'O meni.',
  subtitle: 'Potražite me na društvenim mrežama',
  body: `Rođeni Inđinčan. Inženjer prednje strane softvera, zadužen za sve koji su odabrali JavaScript @Symphony. Umereno zanimljiv i duhovit lik. Sic parvis magna.
  Zbog NDA, neke od projekata ne smem imenovati i linkovati.`,
  projects: [
    {
      text:'Sistem za snimanje glasovnih zapisa za treniranje ML modela.',
    },
    {
      text:'Wolkabout IoT platform - WolkReact',
      to:'https://demo.wolkabout.com/#/auth/login'
    },
    {
      text:'WolkRest - Node.js/Veb klijentska biblioteka za korišćenje WolkAbout REST APIja.',
      to:'https://www.npmjs.com/package/@wolkabout/wolk-rest'
    },
    {
      text:'Ready2Wash klijentski portal',
      to: 'https://customer.ready2wash.net/#/login'
    },
    {
      text:'Klijentska veb aplikacija za biometrijska pretraživanja',
    },
    {
      text:'Klijentska veb aplikacija za upravljanje pametnom kućom',
    },
    {
      text:'Execom Weather app',
      to:'https://www.execom.eu/blog/post/in-our-cloud-you-can-always-tell-the-weather'
    },
    {
      text:'Klijentska veb aplikacija za osiguranje vozila',
    },
  ],
  links: [
    {
      to: 'https://twitter.com/skendza/status/1253716216117633024',
      text: 'skendza',
      Icon: FaTwitter,
      newTab: true,
    },
    {
      to: 'https://www.youtube.com/watch?v=5qbge0io3ac&t=283s',
      text: 'ITPolako',
      Icon: FaYoutube,
      newTab: true,
    },
    {
      to: 'https://www.linkedin.com/in/rskendzic/',
      text: 'rskendzic',
      Icon: FaLinkedin,
      newTab: true,
    },
    {
      to: 'https://github.com/rskendzic',
      text: 'rskendzic',
      Icon: FaGithub,
      newTab: true,
    },
  ],
};

export default content;
