import React from 'react';
import { withStyles } from '@material-ui/styles';

import { BlogPost } from 'components';

import useBlogPosts from './useBlogPosts';
import styles from './style';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const BlogPostList = ({ classes }) => {
  const { allContentfulBlogPost } = useBlogPosts();

  return (
    <React.Fragment>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='primary'>
          Blog.
        </Typography>
      </Fade>
      <Typography variant='h5' color='secondary'>
        S vremena na vreme sam nešto piskarao i snimao, pa pogledajte ima li šta pametno.
      </Typography>
      <Fade in timeout={1000}>
        <div className={classes.container}>
          {allContentfulBlogPost.edges.map(({ node }, index) => (
            <React.Fragment key={index}>
              <BlogPost article={node} />
            </React.Fragment>
          ))}
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default withStyles(styles)(BlogPostList);
