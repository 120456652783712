import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { LinkList } from 'components';

import content from './content';
import styles from './style';
import { withStyles } from '@material-ui/styles';
import { graphql, useStaticQuery } from 'gatsby';

const { title, subtitle, links, body, projects } = content;

const About = ({ classes }) => {
  const { allContentfulPerson: avatar } = useAvatar();
  const source = avatar.edges[0].node.image.fluid.src;
  const avatarName = avatar.edges[0].node.name;

  return (
    <>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='primary'>
          {title}
        </Typography>
      </Fade>
      <img src={source} alt={avatarName} className={classes.avatar} />
      <Fade in timeout={2000}>
        <div className={classes.projects}>
          <Typography variant='body1' color='textSecondary'>
            {body}
          </Typography>

          <Typography variant='h5' color='textSecondary'>
            Najznačajniji projekti na kojima sam radio u prethodnih 5 godina:
          </Typography>
          <ul className={classes.list}>
            {projects.map(({ to, text }, index) => {
              if (to) {
                return (
                  <li key={index} className={classes.bullet}>
                    {' '}
                    <a href={to}>{text}</a>
                  </li>
                );
              }
              return (
                <Typography variant='body1' color={'textSecondary'} key={index}>
                  <li className={classes.bullet}>{text}</li>
                </Typography>
              );
            })}
          </ul>
        </div>
      </Fade>
      <Fade in timeout={3000}>
        <Typography variant='h3' color='textSecondary'>
          {subtitle}
        </Typography>
      </Fade>
      <LinkList links={links} />

    </>
  );
};

const useAvatar = () => {
  return useStaticQuery(
    graphql`
      query {
        allContentfulPerson {
          edges {
            node {
              name
              image {
                fluid(maxHeight: 150) {
                  src
                }
              }
            }
          }
        }
      }
    `
  );
};

export default withStyles(styles)(About);
