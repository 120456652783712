const styles = ({ typography }) => ({
  projects: {
    padding: `${typography.pxToRem(32)} 0`,
    margin: `${typography.pxToRem(16)} 0`,
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  bullet: {
    paddingLeft: `${typography.pxToRem(16)}`,
    paddingTop: `${typography.pxToRem(16)}`,
    textIndent: `-0.7rem`,
    '&:before': {
      content: "'📌    '",
      paddingRight: `${typography.pxToRem(16)}`,
    },
  },
  avatar: {
    padding: `${typography.pxToRem(32)} 0`,
    display: 'flex',
    borderRadius: '50%',
    margin: `${typography.pxToRem(16)} auto`,
  },
});

export default styles;
